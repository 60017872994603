import type { AxiosError } from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { api } from '../../api';
import { getAskForFirstAlertsCommunityModal } from '../../components/FirstAlerts/first-alerts.helper';
import { getViewInternalFACommunities } from '../../helpers/device.helper';
import type { ErrorMessage } from '../../models/Error';
import type { AdvocateAvailability, FirstAlertsCommunity, GuestFirstAlertsCommunity } from '../../models/guest';
import type { DartNotificationContent, NotificationContent } from '../../models/notification';
import type { AsyncThunkConfig } from '../../models/slice';
import { RaygunErrorHandlerService } from '../../services/raygun';

const { logError } = RaygunErrorHandlerService();

export enum FIRST_ALERTS_PAGE {
  MAIN = 'GuestAlert',
  COMMUNITIES = 'GuestAlertCommunity',
  COMMUNITIES_CONFIRM = 'GuestAlertCommunityConfirm',
}

type FirstAlertsSliceType = {
  guestAlertRegistered: boolean;
  guestAlertPromptResponse: boolean | null | undefined;
  guestAlertAcceptedDisclaimer: boolean | null | undefined;
  guestAlertNotification: NotificationContent | undefined;
  guestAlertPage: FIRST_ALERTS_PAGE;
  firstAlertsCommunityList: FirstAlertsCommunity[];
  guestFirstAlertsSelectedCommunityList: GuestFirstAlertsCommunity[];
  guestFirstAlertsCommunityList: GuestFirstAlertsCommunity[];
  guestFirstAlertsLoading: boolean;
  guestFirstAlertsAskingAgain: boolean;
  guestFirstAlertsStartModalDismissed: boolean;
  guestFirstAlertsHasNewCommunity: boolean | undefined;
  dartAlertInfo: DartNotificationContent | undefined;
  dartAdvocateAvailability: AdvocateAvailability | undefined;
  showGuestFirstAlertsCommunityNotFoundModal: boolean;
  showDrawerFromPush: boolean;
};

const initialState: FirstAlertsSliceType = {
  guestAlertRegistered: false,
  guestAlertPromptResponse: undefined,
  guestAlertAcceptedDisclaimer: undefined,
  guestAlertNotification: undefined,
  guestAlertPage: FIRST_ALERTS_PAGE.MAIN,
  firstAlertsCommunityList: [],
  guestFirstAlertsSelectedCommunityList: [],
  guestFirstAlertsCommunityList: [],
  guestFirstAlertsLoading: false,
  guestFirstAlertsAskingAgain: false,
  guestFirstAlertsStartModalDismissed: false,
  guestFirstAlertsHasNewCommunity: undefined,
  dartAlertInfo: undefined,
  dartAdvocateAvailability: undefined,
  showGuestFirstAlertsCommunityNotFoundModal: false,
  showDrawerFromPush: false,
};

export const getFirstAlertsCommunityList = createAsyncThunk<FirstAlertsCommunity[], undefined, AsyncThunkConfig>(
  'firstAlerts/getFirstAlertsCommunityList',
  async (_, thunkAPI) => {
    try {
      const view_internal_fa_communities = getViewInternalFACommunities();
      const response = await api.post<FirstAlertsCommunity[]>('/guest/v1_get_first_alerts_communities', {
        view_internal_fa_communities,
      });
      return response ?? [];
    } catch (e) {
      logError(e, ['firstAlertsSlice', 'getFirstAlertsCommunityList']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getGuestFirstAlertsCommunityList = createAsyncThunk<
  GuestFirstAlertsCommunity[],
  undefined,
  AsyncThunkConfig
>('firstAlerts/getGuestFirstAlertsCommunityList', async (_, thunkAPI) => {
  try {
    const view_internal_fa_communities = getViewInternalFACommunities();
    const response = await api.post<GuestFirstAlertsCommunity[]>('/guest/v0_get_guest_first_alerts_communities', {
      view_internal_fa_communities,
    });
    return response ?? [];
  } catch (e) {
    logError(e, ['firstAlertsSlice', 'getGuestFirstAlertsCommunityList']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const saveGuestFirstAlertsCommunityList = createAsyncThunk<
  boolean,
  GuestFirstAlertsCommunity[],
  AsyncThunkConfig
>('firstAlerts/saveGuestFirstAlertsCommunityList', async (first_alerts_community_list, thunkAPI) => {
  try {
    const response = await api.post<boolean>('/guest/v0_save_guest_first_alerts_communities', {
      first_alerts_community_list,
    });
    return response ?? false;
  } catch (e) {
    logError(e, ['firstAlertsSlice', 'saveGuestFirstAlertsCommunityList']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const resetFirstAlerts = createAsyncThunk<string, undefined, AsyncThunkConfig>(
  'firstAlerts/resetFirstAlerts',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<string>('/guest/v0_reset_first_alerts');
      await thunkAPI.dispatch(getGuestFirstAlertsCommunityList());
      await thunkAPI.dispatch(getAlertPromptResponse());
      await thunkAPI.dispatch(getAlertAcceptedDisclaimer());
      return response ?? '';
    } catch (e) {
      logError(e, ['firstAlertsSlice', 'resetFirstAlerts']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const deleteGuestFirstAlertsCommunity = createAsyncThunk<boolean, string, AsyncThunkConfig>(
  'firstAlerts/deleteGuestFirstAlertsCommunity',
  async (first_alerts_community_id, thunkAPI) => {
    try {
      const response = await api.post<boolean>('/guest/v0_delete_guest_first_alerts_community', {
        first_alerts_community_id,
      });
      await thunkAPI.dispatch(getGuestFirstAlertsCommunityList());
      return response ?? false;
    } catch (e) {
      logError(e, ['firstAlertsSlice', 'deleteGuestFirstAlertsCommunity']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const setAlertPromptResponse = createAsyncThunk<string, boolean, AsyncThunkConfig>(
  'firstAlerts/setAlertPromptResponse',
  async (prompt_response, thunkAPI) => {
    try {
      const response = await api.post<string>('/guest/v0_set_alert_prompt_response', { prompt_response });
      if (response) {
        await thunkAPI.dispatch(getAlertPromptResponse());
      }
      return response ?? ''; // guest_id
    } catch (e) {
      logError(e, ['firstAlertsSlice', 'setAlertPromptResponse']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getAlertPromptResponse = createAsyncThunk<boolean | null | undefined, undefined, AsyncThunkConfig>(
  'firstAlerts/getAlertPromptResponse',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<boolean>('/guest/v0_get_alert_prompt_response');
      return response;
    } catch (e) {
      logError(e, ['firstAlertsSlice', 'getAlertPromptResponse']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const setAlertAcceptedDisclaimer = createAsyncThunk<string, boolean, AsyncThunkConfig>(
  'firstAlerts/setAlertAcceptedDisclaimer',
  async (accepted_disclaimer, thunkAPI) => {
    try {
      const response = await api.post<string>('/guest/v0_set_alert_accepted_disclaimer', { accepted_disclaimer });
      if (response) {
        await thunkAPI.dispatch(getAlertAcceptedDisclaimer());
      }
      return response ?? ''; // guest_id
    } catch (e) {
      logError(e, ['firstAlertsSlice', 'setAlertAcceptedDisclaimer']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getAlertAcceptedDisclaimer = createAsyncThunk<boolean | null | undefined, undefined, AsyncThunkConfig>(
  'firstAlerts/getAlertAcceptedDisclaimer',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<boolean>('/guest/v0_get_alert_accepted_disclaimer');
      return response;
    } catch (e) {
      logError(e, ['firstAlertsSlice', 'getAlertAcceptedDisclaimer']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const checkHasNewFirstAlertsCommunity = createAsyncThunk<boolean, undefined, AsyncThunkConfig>(
  'firstAlerts/checkHasNewFirstAlertsCommunity',
  async (_, thunkAPI) => {
    try {
      const prompt_date = getAskForFirstAlertsCommunityModal();
      if (!prompt_date) return false;
      const response = await api.post<boolean>('/guest/v1_check_latest_first_alerts_community', { prompt_date });
      return response ?? false;
    } catch (e) {
      logError(e, ['firstAlertsSlice', 'checkHasNewFirstAlertsCommunity']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getDartAlertInfo = createAsyncThunk<DartNotificationContent | undefined, undefined, AsyncThunkConfig>(
  'firstAlerts/getDartAlertInfo',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<DartNotificationContent | undefined>('/guest/v0_get_dart_alert_info');
      return response;
    } catch (e) {
      logError(e, ['firstAlertsSlice', 'getDartAlertInfo']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getDartAdvocateAvailability = createAsyncThunk<
  AdvocateAvailability | undefined,
  undefined,
  AsyncThunkConfig
>('firstAlerts/getDartAdvocateAvailability', async (_, thunkAPI) => {
  try {
    const response = await api.post<AdvocateAvailability | undefined>('/guest/v0_get_dart_advocate_availability');
    return response;
  } catch (e) {
    logError(e, ['firstAlertsSlice', 'getDartAdvocateAvailability']);
    return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
  }
});

export const firstAlertsSlice = createSlice({
  name: 'firstAlerts',
  initialState,
  reducers: {
    setGuestFirstAlertsSelectedCommunityList: (state, action) => {
      state.guestFirstAlertsSelectedCommunityList = action.payload;
    },
    setGuestAlertPage: (state, action: PayloadAction<FIRST_ALERTS_PAGE>) => {
      state.guestAlertPage = action.payload;
    },
    setGuestAlertNotification: (state, action: PayloadAction<NotificationContent | undefined>) => {
      state.guestAlertNotification = action.payload;
    },
    setGuestFirstAlertsAskingAgain: (state, action: PayloadAction<boolean>) => {
      state.guestFirstAlertsAskingAgain = action.payload;
    },
    dismissGuestFirstAlertsStartModal: (state) => {
      state.guestFirstAlertsStartModalDismissed = true;
    },
    setShowDrawerFromPush: (state, action: PayloadAction<boolean>) => {
      state.showDrawerFromPush = action.payload;
    },
    setGuestFirstAlertsCommunityNotFoundModal: (state, action: PayloadAction<boolean>) => {
      state.showGuestFirstAlertsCommunityNotFoundModal = action.payload;
    },
    setDartAlertInfo: (state, action: PayloadAction<DartNotificationContent | undefined>) => {
      state.dartAlertInfo = action.payload;
    },
    setGuestFirstAlertsHasNewCommunity: (state, action: PayloadAction<boolean>) => {
      state.guestFirstAlertsHasNewCommunity = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFirstAlertsCommunityList.fulfilled, (state, action) => {
        state.firstAlertsCommunityList = action.payload;
      })
      .addCase(getGuestFirstAlertsCommunityList.pending, (state, _action) => {
        state.guestFirstAlertsLoading = true;
      })
      .addCase(getGuestFirstAlertsCommunityList.fulfilled, (state, action) => {
        state.guestFirstAlertsCommunityList = action.payload;
        state.guestFirstAlertsLoading = false;
      })
      .addCase(getGuestFirstAlertsCommunityList.rejected, (state, _action) => {
        state.guestFirstAlertsLoading = false;
      })
      .addCase(getAlertPromptResponse.fulfilled, (state, action) => {
        state.guestAlertPromptResponse = action.payload;
      })
      .addCase(getAlertAcceptedDisclaimer.fulfilled, (state, action) => {
        state.guestAlertAcceptedDisclaimer = action.payload;
      })
      .addCase(checkHasNewFirstAlertsCommunity.fulfilled, (state, action) => {
        state.guestFirstAlertsHasNewCommunity = action.payload;
      })
      .addCase(getDartAlertInfo.fulfilled, (state, action) => {
        state.dartAlertInfo = action.payload;
      })
      .addCase(getDartAdvocateAvailability.fulfilled, (state, action) => {
        state.dartAdvocateAvailability = action.payload;
      });
  },
});

export const {
  setGuestFirstAlertsSelectedCommunityList,
  setGuestAlertNotification,
  setGuestAlertPage,
  setGuestFirstAlertsAskingAgain,
  dismissGuestFirstAlertsStartModal,
  setShowDrawerFromPush,
  setGuestFirstAlertsCommunityNotFoundModal,
  setDartAlertInfo,
  setGuestFirstAlertsHasNewCommunity,
} = firstAlertsSlice.actions;
