import type { AxiosError } from 'axios';

import type { PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { api } from '../../api';
import { getInitialSessionLog, groupByDay } from '../../helpers/advocate.helper';
import type { AdvocateHoursResponse, Label, LabelGroup, SessionLog, SessionLogResponse } from '../../models/advocate';
import { Profile } from '../../models/advocate';
import { APIStatus } from '../../models/api';
import type { ErrorMessage } from '../../models/Error';
import type { Community } from '../../models/guest';
import type { Language } from '../../models/language';
import type { AsyncThunkConfig } from '../../models/slice';
import { RaygunErrorHandlerService } from '../../services/raygun';

const { logError } = RaygunErrorHandlerService();

export type SessionLogFormated = {
  date: string;
  sessionResponse: SessionLogResponse[];
};

type AdvocateAccountSliceType = {
  profileApiStatus: APIStatus;
  sessionApiStatus: APIStatus;
  profile: Profile;
  editProfile: Profile;
  currentComponent: ACCOUNT_COMPONENT;
  languages: Language[];
  labels: Label[];
  labelGroups: LabelGroup[];
  communities: Community[];
  advocateHours: AdvocateHoursResponse[];
  sessionLogFormated: SessionLogFormated[];
  sessionResponse: SessionLogResponse[];
  isSessionLogSurvey: boolean;
  sessionLog: SessionLog;
};

export enum ACCOUNT_COMPONENT {
  PROFILE = 'profile',
  EDIT_PROFILE = 'editProfile',
  LIVED_EXPERIENCES = 'livedExperiences',
  CHANGE_PASSWORD = 'changePassword',
  SESSION_LOG = 'sessionLog',
}

const initialState: AdvocateAccountSliceType = {
  profileApiStatus: APIStatus.IDLE,
  sessionApiStatus: APIStatus.IDLE,
  profile: new Profile(),
  editProfile: new Profile(),
  currentComponent: ACCOUNT_COMPONENT.PROFILE,
  languages: [],
  labels: [],
  labelGroups: [],
  communities: [],
  advocateHours: [],
  sessionLogFormated: [],
  sessionResponse: [],
  isSessionLogSurvey: false,
  sessionLog: getInitialSessionLog(),
};

export const getAdvocateProfile = createAsyncThunk<Profile, undefined, AsyncThunkConfig>(
  'advocateAccount/getAdvocateProfile',
  async (_, thunkAPI) => {
    try {
      const currentUser = thunkAPI.getState().advocateAuthSlice.currentUser;
      const rootEndpoint = currentUser?.service_provider_organization_id ? 'service_provider' : 'leps';
      const response = await api.get<Profile>(`/advocate/v0_${rootEndpoint}_profile`);
      return response ?? new Profile();
    } catch (e) {
      logError(e, ['advocateAccountSlice', 'getAdvocateProfile']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getAdvocateLanguages = createAsyncThunk<Language[], undefined, AsyncThunkConfig>(
  'advocateAccount/getAdvocateLanguages',
  async (_, thunkAPI) => {
    try {
      const response = await api.get<Language[]>('/advocate/v0_get_languages');
      return response ?? [];
    } catch (e) {
      logError(e, ['advocateAccountSlice', 'getAdvocateLanguages']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getAdvocateLabels = createAsyncThunk<Label[], undefined, AsyncThunkConfig>(
  'advocateAccount/getAdvocateLabels',
  async (_, thunkAPI) => {
    try {
      const response = await api.get<Label[]>('/advocate/v0_get_labels');
      return response ?? [];
    } catch (e) {
      logError(e, ['advocateAccountSlice', 'getAdvocateLabels']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getLabelGroups = createAsyncThunk<LabelGroup[], undefined, AsyncThunkConfig>(
  'advocateAccount/getLabelGroups',
  async (_, thunkAPI) => {
    try {
      const response = await api.get<LabelGroup[]>('/advocate/v1_get_labels');
      return response ?? [];
    } catch (e) {
      logError(e, ['advocateAccountSlice', 'getLabelGroups']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getCommunities = createAsyncThunk<Community[], undefined, AsyncThunkConfig>(
  'advocateAccount/getCommunities',
  async (_, thunkAPI) => {
    try {
      const response = await api.post<Community[]>('/advocate/v0_get_communities');
      return response ?? [];
    } catch (e) {
      logError(e, ['advocateAccountSlice', 'getCommunities']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const setAdvocateProfile = createAsyncThunk<boolean, Profile, AsyncThunkConfig>(
  'advocateAccount/setAdvocateProfile',
  async (profile, thunkAPI) => {
    const isServiceProvider = !!thunkAPI.getState().advocateAuthSlice.currentUser?.service_provider_organization_id;
    try {
      const url = `/advocate/v0_${isServiceProvider ? 'service_provider' : 'leps'}_profile_update`;

      const response = await api.post<boolean>(url, profile);
      return response ?? false;
    } catch (e) {
      logError(e, ['advocateAccountSlice', 'setAdvocateProfile']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getAdvocateSessionLog = createAsyncThunk<SessionLogResponse[], SessionLog, AsyncThunkConfig>(
  'advocateAccount/getAdvocateSessionLog',
  async (sessionLogs, thunkAPI) => {
    try {
      const response = await api.post<SessionLogResponse[]>('/advocate/v0_get_chat_logs', sessionLogs);
      return response ?? [];
    } catch (e) {
      logError(e, ['advocateAccountSlice', 'getAdvocateSessionLog']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const getAdvocateHours = createAsyncThunk<AdvocateHoursResponse[], SessionLog, AsyncThunkConfig>(
  'advocateAccount/getAdvocateHours',
  async (sessionLogs, thunkAPI) => {
    try {
      const response = await api.post<AdvocateHoursResponse[]>('/advocate/v0_get_advocate_hours', sessionLogs);
      return response ?? [];
    } catch (e) {
      logError(e, ['advocateAccountSlice', 'getAdvocateHours']);
      return thunkAPI.rejectWithValue((e as AxiosError<ErrorMessage>).response?.data);
    }
  },
);

export const advocateAccountSlice = createSlice({
  name: 'advocateAccount',
  initialState,
  reducers: {
    setCurrentComponent: (state, action: PayloadAction<ACCOUNT_COMPONENT>) => {
      state.currentComponent = action.payload;
    },
    setSessionLogsSurvey: (state, action: PayloadAction<boolean>) => {
      state.isSessionLogSurvey = action.payload;
    },
    setSessionLog: (state, action: PayloadAction<SessionLog>) => {
      state.sessionLog = action.payload;
    },
    clearAdvocateAccountState: () => initialState,
    updateEditProfile: (state, action: PayloadAction<Partial<Profile>>) => {
      state.editProfile = { ...state.editProfile, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdvocateProfile.pending, (state, _action) => {
        state.profileApiStatus = APIStatus.PENDING;
      })
      .addCase(getAdvocateProfile.rejected, (state, _action) => {
        state.profileApiStatus = APIStatus.ERROR;
      })
      .addCase(getAdvocateProfile.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.editProfile = action.payload;
        state.profileApiStatus = APIStatus.FULFILLED;
      })
      .addCase(getAdvocateLanguages.fulfilled, (state, action) => {
        state.languages = action.payload;
      })
      .addCase(getAdvocateLabels.fulfilled, (state, action) => {
        state.labels = action.payload;
      })
      .addCase(getLabelGroups.fulfilled, (state, action) => {
        state.labelGroups = action.payload;
      })
      .addCase(getCommunities.fulfilled, (state, action) => {
        state.communities = action.payload;
      })
      .addCase(getAdvocateSessionLog.pending, (state, _action) => {
        state.sessionApiStatus = APIStatus.PENDING;
      })
      .addCase(getAdvocateSessionLog.rejected, (state, _action) => {
        state.sessionApiStatus = APIStatus.ERROR;
      })
      .addCase(getAdvocateSessionLog.fulfilled, (state, action) => {
        state.sessionResponse = action.payload;
        state.sessionLogFormated = groupByDay(action.payload);
        state.sessionApiStatus = APIStatus.FULFILLED;
      })
      .addCase(getAdvocateHours.pending, (state, _action) => {
        state.sessionApiStatus = APIStatus.PENDING;
      })
      .addCase(getAdvocateHours.rejected, (state, _action) => {
        state.sessionApiStatus = APIStatus.ERROR;
      })
      .addCase(getAdvocateHours.fulfilled, (state, action) => {
        state.advocateHours = action.payload;
        state.sessionApiStatus = APIStatus.FULFILLED;
      });
  },
});

export const {
  setCurrentComponent,
  setSessionLogsSurvey,
  setSessionLog,
  clearAdvocateAccountState,
  updateEditProfile,
} = advocateAccountSlice.actions;
