import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { close } from 'ionicons/icons';

import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonModal, IonToolbar } from '@ionic/react';

import './ts-modal-drawer-common.scss';

import type { TSModalProps } from './TSModal';

export interface TSDrawerProps extends Omit<TSModalProps, 'heightPercentage'> {
  heightPercentage: number;
}

export const TSDrawer = forwardRef<HTMLIonModalElement, TSDrawerProps>(function BasicTSDrawer(
  {
    className = '',
    dataTestId,
    heightPercentage,
    closeButton = true,
    footerBorder = true,
    isOpen,
    closeModal,
    titleContent,
    mainContent,
    footerContent,
    variant = 'light',
    ...props
  }: TSDrawerProps,
  ref,
) {
  const combinedClassNames = `ts-drawer ts-modal-${variant} ${className}`;
  const headerRef = useRef<HTMLIonHeaderElement>(null);
  const footerRef = useRef<HTMLIonFooterElement>(null);
  const [contentHeight, setContentHeight] = useState(0);

  const updateContentHeight = useCallback(() => {
    const headerHeight = headerRef.current?.clientHeight;
    const footerHeight = footerRef.current?.clientHeight;

    if (headerHeight !== undefined || footerHeight !== undefined) {
      const availableHeight = window.innerHeight - (headerHeight || 0) - (footerHeight || 0);
      const calculatedHeight = availableHeight * (heightPercentage / 100);
      setContentHeight(calculatedHeight);
    }
  }, [heightPercentage]);

  useEffect(() => {
    updateContentHeight();
  }, [updateContentHeight]);

  return (
    <IonModal
      ref={ref}
      data-testid={dataTestId}
      onDidPresent={updateContentHeight}
      isOpen={isOpen}
      className={combinedClassNames}
      onDidDismiss={closeModal}
      handle={false}
      initialBreakpoint={1}
      breakpoints={[0, 1]}
      {...props}
    >
      <div>
        <IonHeader ref={headerRef} className="ion-no-border">
          {closeButton && (
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={closeModal}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          )}
          {titleContent && <div className="title-content">{titleContent}</div>}
        </IonHeader>
        <IonContent style={{ height: contentHeight }} className="padding">
          {mainContent}
        </IonContent>
        {footerContent && (
          <IonFooter className={`${footerBorder ? '' : 'ion-no-border no-footer-border'}`} ref={footerRef}>
            <IonToolbar>{footerContent}</IonToolbar>
          </IonFooter>
        )}
      </div>
    </IonModal>
  );
});
