import { forwardRef, type ReactElement } from 'react';
import { close } from 'ionicons/icons';

import { IonButton, IonButtons, IonFooter, IonHeader, IonIcon, IonModal, IonToolbar } from '@ionic/react';

import './ts-modal-drawer-common.scss';

type IonModalProps = React.ComponentProps<typeof IonModal>;

export interface TSModalProps extends IonModalProps {
  className?: string;
  dataTestId?: string;
  heightPercentage?: number;
  closeButton?: boolean;
  footerBorder?: boolean;
  isOpen: boolean;
  closeModal?: () => void;
  titleContent?: ReactElement;
  mainContent: ReactElement;
  footerContent?: ReactElement;
  variant?: 'light' | 'dark';
}

export const TSModal = forwardRef<HTMLIonModalElement, TSModalProps>(function BasicTSModal(
  {
    className = '',
    dataTestId,
    heightPercentage,
    footerBorder = true,
    variant = 'light',
    isOpen,
    closeModal,
    closeButton,
    titleContent,
    mainContent,
    footerContent,
    ...props
  }: TSModalProps,
  ref,
) {
  const maxHeight = heightPercentage ? `${heightPercentage}vh` : '100%';
  const combinedClassNames = `ts-modal ts-modal-${variant} ${className}`;

  return (
    <IonModal
      ref={ref}
      isOpen={isOpen}
      className={combinedClassNames}
      data-testid={dataTestId}
      onDidDismiss={closeModal}
      backdropDismiss={false}
      handle={false}
      style={
        !heightPercentage
          ? { '--height': 'fit-content' }
          : heightPercentage < 100
            ? { '--height': `${heightPercentage}%`, paddingLeft: 24, paddingRight: 24 }
            : { '--height': '100%' }
      }
      {...props}
    >
      {closeButton && (
        <IonHeader className="ion-no-border">
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton onClick={closeModal}>
                <IonIcon icon={close} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
          {titleContent && <div className="title-content">{titleContent}</div>}
        </IonHeader>
      )}
      <div style={{ maxHeight }} className="content">
        {mainContent}
      </div>
      {footerContent && (
        <IonFooter className={`${footerBorder ? '' : 'ion-no-border no-footer-border'}`}>
          <IonToolbar>{footerContent}</IonToolbar>
        </IonFooter>
      )}
    </IonModal>
  );
});
