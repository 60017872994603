import dayjs from 'dayjs';
import type { FirebaseApp } from 'firebase/app';
import type { MessagePayload, Messaging } from 'firebase/messaging';

import { Capacitor } from '@capacitor/core';
import type { DeviceInfo } from '@capacitor/device';
import type { PushNotificationSchema } from '@capacitor/push-notifications';
import type { Unsubscribe } from '@reduxjs/toolkit';

import { chatIsValid } from '../api/chat';
import { logAlertNotificationRead, saveNotificationToken } from '../api/notification';
import { getUserPermission } from '../components/Permissions/permissions.helper';
import { environment } from '../environment/environment';
import { history } from '../history';
import { type ChatData, ChatPage } from '../models/chat';
import type { NotificationContent } from '../models/notification';
import { PushNotification, PushType } from '../models/notification';
import { refreshToken } from '../pages/advocate/AdvocateAuthSlice';
import {
  ADVOCATE_COMPONENT,
  setAdvocateChatPageStatus,
  setAdvocateChatUserConn,
  setAdvocateEndedChatBy,
  setAdvocateSentFirstMsg,
} from '../pages/advocate/AdvocateChatSlice';
import {
  getDartAdvocateAvailability,
  setDartAlertInfo,
  setGuestAlertNotification,
  setShowDrawerFromPush,
} from '../pages/guest/firstAlertsSlice';
import { getStoredAuthAsync } from '../services/login';
import { RaygunErrorHandlerService } from '../services/raygun';
import { store } from '../store';
import { isNative } from './device.helper';
import { capturePostHogStandardEvent } from './posthog.helper';

let app: FirebaseApp | undefined = undefined;
let messaging: Messaging | undefined = undefined;

const { logError } = RaygunErrorHandlerService();

export const handleNotification = async (
  notification: PushNotificationSchema | MessagePayload,
  ground: 'foreground' | 'background',
) => {
  if (ground === 'background' && isNative() && Capacitor.isPluginAvailable('SplashScreen')) {
    // Hide splash screen if app started from push notification
    const { SplashScreen } = await import('@capacitor/splash-screen');
    SplashScreen.hide();
  }

  const { advocateChatUserConn, advocatePostChatSurvey } = store.getState().advocateChatSlice;
  const isAdvocateInChatOrSurvey = Boolean(advocateChatUserConn?.threadId || advocatePostChatSurvey.thread_id);
  const data = notification.data as ChatData;

  switch (data.type) {
    case PushType.NewChat: {
      if (isAdvocateInChatOrSurvey) {
        capturePostHogStandardEvent('Notification advocate already in chat/survey', {
          data,
          ground,
        });
        logError(
          new Error('Advocate already in chat/survey'),
          ['notification.helper', 'handleNotification', 'case PushType.NewChat'],
          { data, advocateChatUserConn, advocatePostChatSurvey },
        );
        return;
      }

      const response = await store.dispatch(refreshToken());
      if (refreshToken.fulfilled.match(response) && response.payload.token) {
        if (ground === 'background') {
          const chatIsValidResponse = await chatIsValid(data.threadId);
          if (!chatIsValidResponse || !chatIsValidResponse.valid) {
            if (chatIsValidResponse?.ended_by === 'CancelledByGuest') {
              store.dispatch(setAdvocateEndedChatBy('CancelledByGuest'));
              capturePostHogStandardEvent('Notification  advocate entered chat cancelled by guest', { data, ground });
            }
            logError(
              new Error('Background push was not valid'),
              ['notification.helper', 'handleNotification', 'case PushType.NewChat'],
              { data, chatIsValidResponse },
            );
            return;
          }
        }
        store.dispatch(setAdvocateSentFirstMsg(false));
        const newChatUserConn = { ...data };
        delete newChatUserConn.type;
        store.dispatch(setAdvocateChatUserConn(newChatUserConn));
      } else {
        logError(
          new Error('Failed to refresh token'),
          ['notification.helper', 'handleNotification', 'case PushType.NewChat'],
          { data },
        );
        return; // advocate will be logged out if it fails to refresh the token
      }

      capturePostHogStandardEvent('Notification advocate entered new chat', { data, ground });
      history.push('/advocate/chat');
      store.dispatch(setAdvocateChatPageStatus(ADVOCATE_COMPONENT.CHAT));
      break;
    }
    case PushType.NewMessage:
      if (ground === 'foreground') {
        capturePostHogStandardEvent('Notification advocate got new message', { data, ground });
        return;
      }

      if ((await getStoredAuthAsync()).token) {
        history.push('/advocate/chat');
        store.dispatch(setAdvocateChatPageStatus(ADVOCATE_COMPONENT.CHAT));
        capturePostHogStandardEvent('Notification advocate got new message', { data, ground });
      } else {
        const { guestChatUserConn, advocateInChat } = store.getState().guestChatSlice;
        if (guestChatUserConn?.threadId && advocateInChat) {
          const chatPage = advocateInChat.service_provider_organization_id ? ChatPage.SERVICES : ChatPage.LEPS;
          history.push(`/guest/${chatPage}`);
          capturePostHogStandardEvent('Notification guest got new message', { data, ground });
        }
      }
      break;
    case PushType.CancelledChat:
      if (data.threadId === advocateChatUserConn?.threadId) {
        store.dispatch(setAdvocateEndedChatBy('CancelledByGuest'));
        capturePostHogStandardEvent('Notification advocate had chat cancelled by guest', { data, ground });
      }
      break;
    case PushType.ErrorChat:
      if (data.threadId === advocateChatUserConn?.threadId) {
        store.dispatch(setAdvocateEndedChatBy('ErrorStartingChat'));
        capturePostHogStandardEvent('Notification advocate got an error starting chat with guest', { data, ground });
      }
      break;
    case PushType.ExpiredChat:
      if (data.threadId === advocateChatUserConn?.threadId) {
        capturePostHogStandardEvent('Notification advocate attempted to access expired chat with guest', {
          data,
          ground,
        });
        store.dispatch(setAdvocateEndedChatBy('AdvocateTimeout'));
      }
      break;
    case PushType.Alert: {
      const n = notification as PushNotificationSchema;
      const content: NotificationContent = {
        id: n.id,
        title: n.data.title,
        body: n.data.body,
        sent_by: n.data.sent_by,
        sent_timestamp: n.data.sent_timestamp,
      };
      if (n.data.dart) {
        store.dispatch(
          setDartAlertInfo({ ...content, end_timestamp: dayjs(content.sent_timestamp).add(4, 'hours').toISOString() }),
        );
        store.dispatch(getDartAdvocateAvailability());
        store.dispatch(setShowDrawerFromPush(true));
      } else {
        store.dispatch(setGuestAlertNotification(content));
      }
      capturePostHogStandardEvent('Notification advocate/guest read alert', { content, is_dart: n.data.dart, ground });
      logAlertNotificationRead(n.data.alert_id);
    }
  }
};

const backgroundMsgListener = (event: MessageEvent) => {
  const payload = event.data;
  handleNotification(payload, 'background');
};

export const checkNotificationPermission = async () => {
  if (isNative()) {
    if (getUserPermission('notification')) {
      const platform = Capacitor.getPlatform() as DeviceInfo['platform'];

      if (platform === 'android') {
        const { LocalNotifications } = await import('@capacitor/local-notifications');
        if (Capacitor.isPluginAvailable('LocalNotifications')) {
          const { display } = await LocalNotifications.checkPermissions();
          return display === 'granted';
        }
      }

      if (platform === 'ios') {
        const { PushNotifications } = await import('@capacitor/push-notifications');
        if (Capacitor.isPluginAvailable('PushNotifications')) {
          const { receive } = await PushNotifications.checkPermissions();
          return receive === 'granted';
        }
      }
    }
  } else {
    if (Notification?.permission) {
      return Notification.permission === 'granted';
    } else if (navigator.permissions.query) {
      const { state } = await navigator.permissions.query({ name: 'notifications' });
      return state === 'granted';
    }
  }

  return false;
};

let unsubscribes: Unsubscribe[] = [];

export const initWebNotification = async () => {
  const { initializeApp } = await import('@firebase/app');
  const { getMessaging, getToken, isSupported, onMessage } = await import('@firebase/messaging');

  const pushApiSupported = await isSupported();

  if (!pushApiSupported) {
    return false;
  }

  if (!app) {
    app = initializeApp({
      apiKey: environment.firebaseConfig.apiKey,
      authDomain: environment.firebaseConfig.authDomain,
      projectId: environment.firebaseConfig.projectId,
      storageBucket: environment.firebaseConfig.storageBucket,
      messagingSenderId: environment.firebaseConfig.messagingSenderId,
      appId: environment.firebaseConfig.appId,
    });
  }

  if (app && !messaging) {
    messaging = getMessaging(app);
  }

  /**
   * References:
   * https://firebase.google.com/docs/cloud-messaging/js/client#web-modular-api
   * https://firebase.google.com/docs/reference/js/messaging
   * https://developer.mozilla.org/en-US/docs/Web/API/notification
   * https://github.com/firebase/quickstart-js/tree/master/messaging
   * https://developer.mozilla.org/en-US/docs/Web/API/Notifications_API/Using_the_Notifications_API
   * https://webkit.org/blog/13878/web-push-for-web-apps-on-ios-and-ipados/
   */
  if (messaging) {
    getToken(messaging, {
      vapidKey: environment.firebaseConfig.vapidKey,
    })
      .then((currentToken) => {
        if (currentToken) {
          if (environment.environment_deploy === 'local') {
            // eslint-disable-next-line no-console
            console.log('currentToken: ', currentToken);
          }
          saveNotificationToken(currentToken);
        } else {
          // No registration token available. Request permission to generate one.
          Notification.requestPermission()
            .then((permission) => {
              if (messaging && permission === 'granted') {
                getToken(messaging, {
                  vapidKey: environment.firebaseConfig.vapidKey,
                }).then((currentToken) => {
                  if (currentToken) {
                    saveNotificationToken(currentToken);
                  }
                });
              } else {
                logError(new Error('Permision not granted.'), [
                  'notification.helper',
                  'initWebNotification',
                  'requestPermission',
                ]);
              }
            })
            .catch((error) => {
              logError(error, ['notification.helper', 'initWebNotification', 'requestPermission']);
            });
        }
      })
      .catch((error) => {
        if (environment.environment_deploy === 'local') {
          // eslint-disable-next-line no-console
          console.error('An error occurred while retrieving token.', error);
        }
        logError(error, ['notification.helper', 'initWebNotification', 'getToken']);
      });
  }

  /** in the foreground (has focus) */
  if (messaging) {
    // unsubscribes previous onMessage subscriptions
    unsubscribes.forEach((unsubscribe) => unsubscribe());
    unsubscribes = [];

    const unsubscribe = onMessage(messaging, (payload) => {
      handleNotification(payload, 'foreground');
    });

    unsubscribes.push(unsubscribe);
  }

  /** in the background, hidden behind other tabs, or completely closed */
  if (messaging) {
    // remove listener before adding another one
    navigator.serviceWorker.removeEventListener('message', backgroundMsgListener);
    navigator.serviceWorker.addEventListener('message', backgroundMsgListener);
  }

  return true;
};

export const initNativeNotification = async (firstTime?: boolean) => {
  if (firstTime || getUserPermission('notification')) {
    return new PushNotification().register();
  }
};
