import type { PostHogConfig, Properties } from 'posthog-js';
import posthog from 'posthog-js';

import { environment } from '../environment/environment';
import { getStoredAuthAsync } from '../services/login';

// https://posthog.com/docs/integrate/client/js#config
const options: Partial<PostHogConfig> = {
  api_host: 'https://app.posthog.com',
  property_denylist: ['email', 'pass', 'retype_pass', 'new_pass', 'old_pass'],
  person_profiles: 'identified_only',
};

posthog.init(environment.posthogToken, options);

export { posthog as posthogClient };

export const capturePostHogStandardEvent = async (
  eventName: string,
  customProperties?: Properties | null | undefined,
) => {
  const properties: Properties = {
    role: (await getStoredAuthAsync()).token ? 'Advocate' : 'Guest',
    ...customProperties,
  };

  posthog?.capture(eventName, properties);
};
