import React from 'react';
import { PostHogProvider } from 'posthog-js/react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

import './orgHeadConfig';
import './i18n';

import App from './App';
import { posthogClient } from './helpers/posthog.helper';
import { AuthPersist } from './routes/AuthPersist';
import { store } from './store';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PostHogProvider client={posthogClient}>
          <AuthPersist>
            <App />
          </AuthPersist>
        </PostHogProvider>
      </Provider>
    </React.StrictMode>,
  );
}

// required for using Camera on web (https://capacitorjs.com/docs/web/pwa-elements)
defineCustomElements(window);
