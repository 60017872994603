import { useTranslation } from 'react-i18next';

import { IonCol, IonRow } from '@ionic/react';

import { TSButton, TSModal } from '../../components/atomic';
import { isBurns } from '../../helpers/device.helper';

interface AdvocateChatEndedModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: string;
  subHeader: string;
}

export const AdvocateChatEndedModal = ({ isOpen, message, subHeader, onClose }: AdvocateChatEndedModalProps) => {
  const { t } = useTranslation();
  return (
    <TSModal
      className="report-chat-modal"
      variant={isBurns ? 'dark' : 'light'}
      isOpen={isOpen}
      mainContent={
        <>
          <IonRow className="ion-padding-top">
            <IonCol className="headline-small text-bold">Chat ended</IonCol>
          </IonRow>
          <IonRow>
            <IonCol>
              <span>{subHeader}</span>
              <span dangerouslySetInnerHTML={{ __html: message }} />
            </IonCol>
          </IonRow>
          <IonRow className="ion-padding-vertical ion-justify-content-center">
            <TSButton variant={isBurns ? 'burns-gold' : 'black'} onClick={onClose}>
              {t('generic.ok')}
            </TSButton>
          </IonRow>
        </>
      }
    />
  );
};
