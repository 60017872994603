import dayjs from 'dayjs';
import Duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import type { AdvocateHoursResponse, SessionLogResponse } from '../models/advocate';
import type { SessionLogFormated } from '../pages/advocate/AdvocateAccountSlice';
import { isSK } from './device.helper';

dayjs.extend(Duration);
dayjs.extend(utc);
dayjs.extend(timezone);

export const months = [
  { text: 'January', value: 1 },
  { text: 'February', value: 2 },
  { text: 'March', value: 3 },
  { text: 'April', value: 4 },
  { text: 'May', value: 5 },
  { text: 'June', value: 6 },
  { text: 'July', value: 7 },
  { text: 'August', value: 8 },
  { text: 'September', value: 9 },
  { text: 'October', value: 10 },
  { text: 'November', value: 11 },
  { text: 'December', value: 12 },
];

const startYear = 2022;
export const years = Array.from({ length: new Date().getFullYear() - startYear + 1 }, (_v, i) => {
  const year = startYear + i;
  return { text: year.toString(), value: year };
});

export const formatDateByOrgTimezone = (dateString: string, dateFormatType: string) => {
  const timezone = isSK ? 'America/Regina' : dayjs.tz.guess();
  return dayjs(dateString).tz(timezone).format(dateFormatType);
};

export const chatCompleted = (sessionResponse: SessionLogResponse[]) =>
  sessionResponse.filter(({ advocate_post_chat_survey_complete }) => advocate_post_chat_survey_complete).length;

export const totalHours = (advocateHours: AdvocateHoursResponse[]) => {
  let hours = 0;

  if (advocateHours) {
    advocateHours.map((totalTime) => {
      const duration = dayjs.duration(totalTime.time_online).asHours();
      hours += duration;
      return hours.toFixed(1);
    });
  }
  return hours.toFixed(1);
};

export const getInitialSessionLog = () => {
  return {
    month: months.find((x, i) => i === new Date().getMonth())?.value,
    year: years.find((x) => x.value === new Date().getFullYear())?.value,
  };
};

export const groupByDay = (data: SessionLogResponse[]): SessionLogFormated[] => {
  const groups = data.reduce<Record<string, SessionLogResponse[]>>((groups, eachData: SessionLogResponse) => {
    const date = formatDateByOrgTimezone(eachData.trigger_timestamp, 'YYYY-MM-DD');
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(eachData);
    return groups;
  }, {});

  return Object.keys(groups).map((date) => {
    return {
      date: date,
      sessionResponse: groups[date],
    };
  });
};
