import { useCallback } from 'react';
import type { CaptureOptions, Properties } from 'posthog-js';
import { usePostHog } from 'posthog-js/react';

export const useCapturePostHog = () => {
  const posthog = usePostHog();

  const capturePostHogCustomEvent = useCallback(
    (eventName: string, customProperties?: Properties | null | undefined) => {
      const options: CaptureOptions = { timestamp: new Date() };
      posthog?.capture(eventName, customProperties, options);
    },
    [posthog],
  );

  return { capturePostHogCustomEvent } as const;
};
