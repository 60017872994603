import type React from 'react';

import { IonAvatar, IonImg } from '@ionic/react';

import './TSAvatar.scss';

import { isBurns } from '../../../helpers/device.helper';

interface TSAvatarProps {
  imgUrl: string | null | undefined;
  name?: string | null;
  className?: string;
  height?: number;
  width?: number;
  grayscaled?: boolean;
}

export const TSAvatar: React.FC<TSAvatarProps> = ({
  imgUrl,
  name,
  className,
  height = 70,
  width = height,
  grayscaled,
}) => {
  return (
    <IonAvatar className={`advocate-avatar ${className} ${isBurns ? 'burns' : ''}`} style={{ height, width }}>
      {imgUrl ? (
        <IonImg
          className={`${grayscaled ? 'unavailable-grayscaled' : ''}`}
          alt="Advocate's profile picture"
          src={imgUrl}
        />
      ) : (
        <p className="title-large bold-text ion-text-uppercase">{name && name.charAt(0)}</p>
      )}
    </IonAvatar>
  );
};
