import type { Language } from '../models/language';
import { isBurns } from './device.helper';

export const appLanguageList: Language[] = [
  { language_code: 'nsk', name: 'ᐃᔪᐤ ᐃᔨᒧᐅᓐ', english_name: 'Naskapi', order: 65 },
  { language_code: 'xnd', name: 'Denesųłiné', english_name: 'Dene', order: 50 },
  { language_code: 'en', name: 'English', english_name: 'English', order: 20 },
  { language_code: 'es', name: 'Español', english_name: 'Spanish', order: 15 },
  { language_code: 'fr', name: 'Français', english_name: 'French', order: 25 },
  { language_code: 'moe', name: 'Innu Aimun', english_name: 'Innu', order: 85 },
  { language_code: 'lkt', name: "Lakhól'iyapi", english_name: 'Lakota', order: 30 },
  { language_code: 'crs', name: 'Maskékowak', english_name: 'Swampy Cree', order: 40 },
  { language_code: 'crk', name: 'Nehiyawēwin', english_name: 'Plains Cree', order: 70 },
  { language_code: 'cwd', name: 'Nihithawīwin', english_name: 'Woodland Cree', order: 75 },
].filter((language) => (isBurns ? ['en', 'fr'].includes(language.language_code) : true));
