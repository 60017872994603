import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { IonCol, IonIcon, IonRow } from '@ionic/react';

import { getCustomIcon } from '../../helpers/utils.helper';

export const GuestWaiting = forwardRef<HTMLIonRowElement>(function GuestWaitingBasic(_props, ref) {
  const { t } = useTranslation();

  // Don't add margin to the IonRow. Not include in the offsetHeight for chat height calculation
  return (
    <IonRow ref={ref} className="ion-padding ion-justify-content-evenly">
      <IonCol size="11" sizeLg="3" className="guest-waiting">
        <IonRow className="ion-align-items-center">
          <IonCol size="1.5">
            <IonRow className="ion-justify-content-center">
              <IonIcon icon={getCustomIcon('person-message-bubble')} />
            </IonRow>
          </IonCol>
          <IonCol size="10.5" class="ion-no-padding">
            <IonRow className="ion-justify-content-start title-medium guest-waiting-text">
              {t('advocateChatPage.guestIsWaiting')}
            </IonRow>
          </IonCol>
        </IonRow>
      </IonCol>
    </IonRow>
  );
});
