import { useEffect } from 'react';
import { alertCircleOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { IonCol, IonContent, IonGrid, IonIcon, IonRow, IonText, useIonAlert } from '@ionic/react';

import '../../pages/advocate/AdvocateChat.scss';

import { appName, isBurns, isNative } from '../../helpers/device.helper';
import { useCheckNotification } from '../../hooks';
import { useCapturePostHog } from '../../hooks/useCapturePostHog';
import type { DispatchedAsyncThunk } from '../../models/slice';
import { refreshToken } from '../../pages/advocate/AdvocateAuthSlice';
import {
  getAdvocateAvailableStatus,
  setAdvocateAvailable,
  setAdvocateUnavailable,
} from '../../pages/advocate/AdvocateChatSlice';
import { useAppDispatch, useAppSelector } from '../../store';
import { TSButton } from '../atomic';

const INTERVAL = 30 * 1000;

const getWarningMessage = (
  isBrowserNotificationSupported: boolean,
  hasNotificationPermission: boolean,
  hasNotificationTokenSaved: boolean,
) => {
  if (!isNative() && !isBrowserNotificationSupported) {
    return 'This browser does not support push notifications. ';
  } else if (!hasNotificationPermission) {
    return `Oops! You must update your settings to receive chat requests. ${
      isNative()
        ? `Please delete the ${appName} app, then go to the App Store to reinstall the app again. Then open the ${appName} app and select “Allow” to accept notifications. `
        : 'Please contact your Manager for instructions. '
    }`;
  } else if (!hasNotificationTokenSaved) {
    return 'Push notifications are granted but the token was not properly stored. ';
  }
};

export const AdvocateHomePage = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { capturePostHogCustomEvent } = useCapturePostHog();
  const [present, dismiss] = useIonAlert();
  const { isBrowserNotificationSupported, hasNotificationPermission, hasNotificationTokenSaved } =
    useCheckNotification();
  const advocateIsAvailable = useAppSelector((state) => state.advocateChatSlice.advocateIsAvailable);
  const firstName = useAppSelector((state) => state.advocateAuthSlice.authResult.first_name);
  const internal = useAppSelector((state) => state.advocateAuthSlice.authResult.internal);
  const cannotReceivePushNotifications =
    (!isNative() && !isBrowserNotificationSupported) ||
    (!isBurns && (!hasNotificationPermission || !hasNotificationTokenSaved));
  const onlineTextClass = isBurns ? 'bright-gold' : 'sky-blue';

  useEffect(() => {
    const outer = dispatch(getAdvocateAvailableStatus());
    let inner: DispatchedAsyncThunk<boolean>;
    const intervalId = setInterval(() => {
      inner = dispatch(getAdvocateAvailableStatus());
    }, INTERVAL);

    return () => {
      clearInterval(intervalId);
      outer?.abort();
      inner?.abort();
    };
  }, [dispatch]);

  const onChangeAvailabeStatus = async () => {
    if (cannotReceivePushNotifications) {
      present({
        header: 'Notifications Issue',
        message: '<p>You cannot update your online status because you cannot receive chat requests.<p>',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              capturePostHogCustomEvent('AdvocateHomePage dismissed notification issue alert');
              dismiss();
            },
          },
        ],
      });
    } else if (!advocateIsAvailable && internal) {
      present({
        header: 'Cannot go online',
        message: '<p>Your account is not enabled to go online. Speak to your manager about enabling this account.<p>',
        buttons: [
          {
            text: 'OK',
            handler: () => {
              capturePostHogCustomEvent('AdvocateHomePage dismissed "cannot go online" alert');
              dismiss();
            },
          },
        ],
      });
    } else {
      const response = await dispatch(refreshToken());
      if (refreshToken.fulfilled.match(response) && response.payload.token) {
        if (advocateIsAvailable) {
          await dispatch(setAdvocateUnavailable());
        } else {
          const response = await dispatch(setAdvocateAvailable());
          if (setAdvocateAvailable.rejected.match(response) && response.payload?.status === 422) {
            present({
              header: 'Cannot go online',
              message: '<p>You must fill out your profile before you can go online.<p>',
              buttons: [
                {
                  text: 'Go to profile',
                  handler: () => {
                    history.push('/advocate/account');
                    capturePostHogCustomEvent('AdvocateHomePage went to complete profile');
                    dismiss();
                  },
                },
                {
                  text: 'Cancel',
                  handler: () => {
                    capturePostHogCustomEvent('AdvocateHomePage dismissed "cannot go online" profile alert');
                    dismiss();
                  },
                },
              ],
            });
            return;
          }
        }
        await dispatch(getAdvocateAvailableStatus());
        capturePostHogCustomEvent('AdvocateHomePage changed available status', {
          available_status: advocateIsAvailable,
        });
      }
    }
  };

  return (
    <IonContent>
      <IonGrid class="page-grid-align-vertical">
        <IonRow class="ion-justify-content-center ion-padding-horizontal ion-align-items-center page-grid-align-vertical ">
          <IonCol sizeSm="4" size="12">
            {cannotReceivePushNotifications && (
              <IonRow className="io-margin ion-justify-content-center advocate-notification-status">
                <IonCol size="1">
                  <IonIcon icon={alertCircleOutline} />
                </IonCol>
                <IonCol size="11">
                  <IonText>
                    {getWarningMessage(
                      isBrowserNotificationSupported,
                      hasNotificationPermission,
                      hasNotificationTokenSaved,
                    )}
                    {isNative() && <b>If you continue to have issues, please contact your Manager.</b>}
                  </IonText>
                </IonCol>
              </IonRow>
            )}
            <IonRow className="ion-margin ion-justify-content-center advocate-home-name">
              <IonText className="headline-large text-bold">
                {t('advocateHomePage.titleGreeting', { name: firstName })}
              </IonText>
            </IonRow>
            <IonRow className="ion-justify-content-center">
              <IonText className="title-large">
                {/* We keep "online" just for advocate front-end view */}
                {t('advocateHomePage.bodyStatus')}{' '}
                <span className={`text-bold ${advocateIsAvailable ? onlineTextClass : ''}`}>
                  {t(advocateIsAvailable ? 'advocateHomePage.labelOnline' : 'advocateHomePage.labelOffline')}
                </span>
              </IonText>
            </IonRow>
            <IonRow className="ion-justify-content-center ion-text-center">
              <IonText className={`ion-padding title-large ${advocateIsAvailable ? onlineTextClass : ''}`}>
                {t(advocateIsAvailable ? 'advocateHomePage.bodyOnline' : 'advocateHomePage.bodyOffline')}
              </IonText>
            </IonRow>
            <IonRow className="ion-margin ion-justify-content-center">
              <TSButton
                variant={advocateIsAvailable ? 'black' : isBurns ? 'burns-gold' : 'default'}
                onClick={onChangeAvailabeStatus}
                size="small"
              >
                {/* We keep "online" just for advocate front-end view */}
                {t(
                  advocateIsAvailable
                    ? 'advocateHomePage.buttonChangeStatusOffline'
                    : 'advocateHomePage.buttonChangeStatusOnline',
                )}
              </TSButton>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};
