import { IonCard, IonCol, IonContent, IonGrid, IonRow } from '@ionic/react';

import { isBurns } from '../../../helpers/device.helper';

export const AdvocatePostChatSurveyContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <IonContent>
      <IonGrid className="ion-no-padding page-grid-align-vertical">
        <IonRow className="ion-padding ion-justify-content-center ion-align-items-center page-grid-align-vertical">
          <IonCol className="ion-padding" sizeSm="4" size="12">
            <IonCard className={`survey-card ${isBurns ? 'tbw-card' : 'ts-card'} ion-no-margin`}>{children}</IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};
