import type { AvatarPersonaData } from '@azure/communication-react';

export const getCustomTheme = (forServiceProviders: boolean) => {
  return {
    palette: {
      themePrimary: '#000000', //icon and message status colours
      themeLighterAlt: '#fffefa',
      themeLighter: '#000000',
      themeLight: forServiceProviders ? '#C3D7E9' : '#E6DED8', //your chat background colour
      themeTertiary: '#fff3b9',
      themeSecondary: '#ffee98',
      themeDarkAlt: '#e6d47c',
      themeDark: '#c2b369',
      themeDarker: '#8f844d',
      neutralLighterAlt: '#c4c9d5',
      neutralLighter: '#FFFFFF', //other chatter background colour
      neutralLight: '#b9bec9',
      neutralQuaternaryAlt: '#adb1bc',
      neutralQuaternary: '#d0d0d0',
      neutralTertiaryAlt: '#9ea2ac',
      neutralTertiary: '#595959',
      neutralSecondary: '#373737',
      neutralPrimaryAlt: '#2f2f2f',
      neutralPrimary: '#000000', //chat text colour
      neutralDark: '#151515',
      black: '#0b0b0b',
      white: 'rgba(255,255,255,0)', //background colour
    },
  };
};

export const avatarPersonaData: AvatarPersonaData = {
  text: '',
  imageUrl: '',
  initialsColor: '',
  imageInitials: '',
};
