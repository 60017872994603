import { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useLocation } from 'react-router';

const getPage = (pathname: string) => {
  const parts = pathname.split('/');
  return ['advocate', 'guest'].includes(parts[1]) ? `${parts[1]}-${parts[2]}` : (parts[1] ?? parts[0]);
};

export const useCapturePostHogPageView = () => {
  const location = useLocation();
  const posthog = usePostHog();
  const page = getPage(location.pathname);

  useEffect(() => {
    posthog?.capture(`Entered ${page} page`);
    return () => {
      posthog?.capture(`Left ${page} page`);
    };
  }, [page, posthog]);
};
