import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ChatAdapter, CompositeLocale } from '@azure/communication-react';
import {
  ChatComposite,
  COMPONENT_LOCALE_EN_US,
  COMPONENT_LOCALE_FR_FR,
  COMPOSITE_LOCALE_EN_US,
  COMPOSITE_LOCALE_FR_FR,
} from '@azure/communication-react';
import { IonCol, IonContent, IonGrid, IonRow } from '@ionic/react';

import './Chat.component.scss';

import { avatarPersonaData, getCustomTheme } from '../../helpers/chat.helper';
import { isBurns } from '../../helpers/device.helper';
import { OrgTraceImage } from '../../theme/OrgTraceImage';
import { GuestWaiting } from '../advocate/GuestWaiting';

export const Chat = ({
  chatAdapter,
  forServiceProviders,
  showGuestWaiting,
}: {
  chatAdapter: ChatAdapter;
  forServiceProviders: boolean;
  showGuestWaiting?: boolean;
}) => {
  const { i18n } = useTranslation();
  const locale: CompositeLocale = {
    strings: i18n.language === 'fr' ? COMPOSITE_LOCALE_FR_FR.strings : COMPOSITE_LOCALE_EN_US.strings,
    component: i18n.language === 'fr' ? COMPONENT_LOCALE_FR_FR : COMPONENT_LOCALE_EN_US,
  };
  const contentRef = useRef<HTMLIonContentElement>(null);
  const guestWaitingRef = useRef<HTMLIonRowElement>(null);
  const [chatContentHeight, setChatContentHeight] = useState(0);
  const [guestWaitingContentHeight, setGuestWaitingChatContentHeight] = useState(0);

  useEffect(() => {
    if (!contentRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setChatContentHeight(contentRef.current?.clientHeight || 0);
      setGuestWaitingChatContentHeight(guestWaitingRef.current?.offsetHeight || 0); // offsetHeight to include the padding
    });
    resizeObserver.observe(contentRef.current);
    if (!guestWaitingRef.current) return;
    resizeObserver.observe(guestWaitingRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <IonContent ref={contentRef} fullscreen scrollY={false}>
      <IonGrid
        className={`ion-no-padding ph-no-capture ${isBurns ? 'tbw-chat-bg' : forServiceProviders ? 'ts-ligth-blue-bg' : 'ts-sand-brown-bg'}`}
      >
        {showGuestWaiting && <GuestWaiting ref={guestWaitingRef} />}
        <IonRow className="ion-justify-content-center ">
          <IonCol
            className={`chat-col ${isBurns ? 'burns' : ''}`}
            style={{ height: chatContentHeight - guestWaitingContentHeight }}
          >
            <ChatComposite
              fluentTheme={getCustomTheme(forServiceProviders)}
              onFetchAvatarPersonaData={() => Promise.resolve(avatarPersonaData)}
              options={{ topic: false }}
              adapter={chatAdapter}
              locale={locale}
            />
            <OrgTraceImage />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};
