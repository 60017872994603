import type { AdapterError } from '@azure/communication-react';

import type { PushType } from './notification';

export type TokenResponse = {
  user: {
    communicationUserId: string;
  };
  token: string;
  expiresOn: string;
};

export type AdvocateInChat = {
  advocate_id: string;
  name: string;
  img_url: string | null;
  service_provider_organization_id: string;
};

export type ChatUserConn = {
  token: string;
  communicationUserId: string;
  endpointUrl: string;
  threadId: string;
  displayName: string;
  firstAdvocateMsgSent?: boolean;
  lastGuestMsgTime?: number;
  advocate_unavailable?: boolean;
  advocate: AdvocateInChat;
};

export type ChatData = ChatUserConn & {
  type?: PushType;
};

export type ChatEndedBy =
  | 'Guest'
  | 'Advocate'
  | 'GuestTimeout'
  | 'AdvocateTimeout'
  | 'AdvocateDecline'
  | 'ConnectionTimeout'
  | 'CancelledByGuest'
  | 'GuestReportedAbuse'
  | 'AdvocateReportedAbuse'
  | 'AdvocateReportedEmergency'
  | 'ErrorStartingChat'
  | 'GuestLeftChat'
  | 'AdvocateLeftChat';

export interface ChatAdapterError extends AdapterError {
  innerError: Error & { statusCode?: number };
}

export enum GUEST_COMPONENT {
  ADVOCATE_SELECT = 'AdvocateSelect',
  CHAT = 'GuestChat',
  CHAT_SESSION_ERROR = 'GuestChatSessionError',
  DART_CHAT_SESSION_ERROR = 'GuestDartChatSessionError',
}

export enum ChatStatus {
  AVAILABLE = 'available',
  BUSY = 'in_chat',
}

export enum ChatPage {
  LEPS = 'chat',
  SERVICES = 'services',
}
