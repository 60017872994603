import type { TFunction } from 'i18next';

import type { Label, LabelGroup, Profile } from '../models/advocate';

export const getCustomIcon = (icon: string) => {
  let src = `assets/icons/${icon}.svg`;
  if (process.env.NODE_ENV === 'test') {
    src = 'public/' + src;
  }
  const url = new URL(src, window.location.origin);
  return url.href;
};

export const renderMultiSelectValue = (t: TFunction, length: number, first: string | undefined, label: string) => {
  if (length) {
    if (length === 1) {
      return first;
    } else {
      return `${length} ${label} ${t('generic.selected')}`;
    }
  }
  return undefined;
};

export const getRCMPCAFStatus = (t: TFunction, profile: Profile) =>
  (['RCMP', 'CAF'] as const)
    .filter((label) => profile.labels.includes(label))
    .map((label) => t(`labels.${label}`))
    .join('/');

export const updateLabelsWithSelections = (labelGroup: LabelGroup, selectedItems: Label[], prevLabelIds: string[]) => {
  const labelGroupIds = labelGroup.labels.map((l) => l.id);
  const prevLabelIdsWithoutGroup = prevLabelIds.filter((id) => !labelGroupIds.includes(id));
  return [...prevLabelIdsWithoutGroup, ...selectedItems.map((l) => l.id)];
};
