import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';

import { Capacitor } from '@capacitor/core';

const getIsOnline = async () => {
  let online = navigator.onLine;
  if (Capacitor.isPluginAvailable('Network')) {
    const { Network } = await import('@capacitor/network');
    online = (await Network.getStatus()).connected;
  }

  return online;
};

export const api = {
  get: async <T>(url: string, config?: AxiosRequestConfig | undefined) => {
    const online = await getIsOnline();
    if (online) {
      const response = await axios.get<T>(url, config);
      return response?.data;
    } else {
      return undefined;
    }
  },
  post: async <T>(url: string, body?: object, config?: AxiosRequestConfig | undefined) => {
    const online = await getIsOnline();
    if (online) {
      const response = await axios.post<T>(url, body, config);
      return response?.data;
    } else {
      return undefined;
    }
  },
};
