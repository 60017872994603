import { useTranslation } from 'react-i18next';

import type { ChatAdapter } from '@azure/communication-react';
import { IonCard, IonCol, IonContent, IonGrid, IonRow, IonText } from '@ionic/react';

import { isBurns } from '../../helpers/device.helper';
import { useAppSelector } from '../../store';
import { TSButton } from '../atomic';

export const AdvocateEndChat = ({
  endChatModal,
  closeModal,
}: {
  endChatModal: () => Promise<void>;
  closeModal: () => void;
  chatAdapter: ChatAdapter | undefined;
  isAdvocate: boolean;
  advocateSentFirstMsg: boolean;
}) => {
  const { t } = useTranslation();
  const advocateReportedAbuse = useAppSelector((state) => state.advocateChatSlice.advocateReportedAbuse);

  return (
    <IonContent fullscreen>
      <IonGrid className="page-grid-align-vertical">
        <IonRow className="ion-justify-content-center ion-align-items-center page-grid-align-vertical">
          <IonCol sizeSm="4" size="12">
            <IonRow className="ion-margin">
              <IonCard className={`main-card ${isBurns ? 'burns' : ''}`}>
                <IonRow>
                  <IonText className="title-medium-extra text-bold modal-title">
                    {t('advocateChatPage.endChatTitle')}
                  </IonText>
                </IonRow>
                <IonRow className="ion-margin-vertical">
                  <IonText>
                    {t(advocateReportedAbuse ? 'advocateChatPage.endReportedChatBody' : 'advocateChatPage.endChatBody')}
                  </IonText>
                </IonRow>
                <IonRow className="ion-margin-bottom ion-justify-content-center">
                  <TSButton
                    variant={isBurns ? 'burns-gold' : 'black'}
                    shadow={!isBurns}
                    mode="ios"
                    shape="round"
                    size="small"
                    onClick={endChatModal}
                  >
                    {t('generic.agree')}
                  </TSButton>
                </IonRow>
                <IonRow className="ion-justify-content-center">
                  <TSButton
                    variant={isBurns ? 'burns-black' : 'white'}
                    shadow={!isBurns}
                    mode="ios"
                    shape="round"
                    size="small"
                    onClick={closeModal}
                  >
                    {t('generic.cancel')}
                  </TSButton>
                </IonRow>
              </IonCard>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};
