import type { RouteProps } from 'react-router';
import { Redirect, Route } from 'react-router';

import { environment } from '../environment/environment';
import { useAppSelector } from '../store';

const isProd = environment.environment_deploy === 'prod';

export const AuthRoute = ({ children, ...rest }: RouteProps) => {
  const hasToken = useAppSelector((state) => state.advocateAuthSlice.hasToken);

  if (!hasToken) {
    return <Redirect to="/advocate-login" />;
  }

  return <Route {...rest}>{children}</Route>;
};

export const DesignRoute = ({ children, ...rest }: RouteProps) => {
  if (isProd) {
    return <Redirect to="/guest/chat" />;
  }

  return <Route {...rest}>{children}</Route>;
};
