export type Community = {
  name: string;
  id: string;
  treaty_council_id: string | null;
};

export type FirstAlertsCommunity = {
  id: string;
  name: string;
  type: number;
};

export type GuestFirstAlertsCommunity = {
  first_alerts_community_id: string;
  name: string;
};

export type FindAdvocates = {
  community_ids: string[];
  languages: string[];
};

export type ChatSessionHelpful = {
  ms_comm_thread_id: string | null;
  helpful: boolean;
};

export type ChatSessionFeedback = {
  ms_comm_thread_id: string | undefined;
  feedback: string | undefined;
};

export type AdvocateAvailability = {
  num_online: number;
  num_available: number;
  avatars: string[];
};

export enum FilterModalStatus {
  IDLE = 'idle',
  COMMUNITIES = 'communities',
  LANGUAGES = 'languages',
}

export enum GuestChatActivityModalStatus {
  IDLE = 'idle',
  CHAT_CONNECT = 'chat_connect',
  CHAT_LOADING = 'chat_loading',
  CHAT_HELPFUL = 'chat_helpful',
  CHAT_REPORTED = 'chat_reported',
  POST_CHAT_SURVEY_FEEDBACK = 'post_chat_survey_feedback',
  CHAT_CONNECT_FAILED_FEEDBACK = 'chat_connect_failed_feedback',
  CONTINUE_CHAT = 'continue_chat',
  END_CHAT = 'end_chat',
}

export enum OnboardingScreenState {
  LOADING,
  LANGUAGE_SELECT,
  WELCOME_TITLE,
  WELCOME_INFO,
  PREFERENCES,
}
