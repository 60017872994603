import { useEffect, useState } from 'react';
import type { ReactNode } from 'react';

import { refreshToken } from '../pages/advocate/AdvocateAuthSlice';
import { getStoredAuthAsync, removeStoredAuthAsync } from '../services/login';
import { useAppDispatch } from '../store';

export const AuthPersist = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const getPersistedState = async () => {
      const storedAuthResult = await getStoredAuthAsync();

      if (storedAuthResult?.token) {
        await dispatch(refreshToken());
        const response = await dispatch(refreshToken());

        // remove stored auth if expired
        if (refreshToken.rejected.match(response) && response.payload?.status === 401) {
          await removeStoredAuthAsync();
        }
      }

      setReady(true);
    };

    getPersistedState();
  }, [dispatch]);

  if (!ready) {
    return null;
  }

  return <>{children}</>;
};
